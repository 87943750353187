<template>
  <div class="row">
    <v-col
      cols="12"
    >
     <shop-docs-alert  v-if="!statistics.account || !statistics.account.details_submitted" :url="statistics.stripe_onboarding_url" />
    </v-col>
    <v-col
      cols="12"
      sm="6"
      lg="4"
    >
<!--      <router-link
        to="/wallet"
        class="text-decoration-none"
      >-->
        <v-card :href="user && statistics.account && statistics.account.details_submitted?statistics.stripe_dashboard_url:statistics.stripe_onboarding_url" target="_blank">
          <div class="px-5 py-9 text-center">
            <h4 class="font-600 grey--text text--darken-2 mb-2">
              {{ $t("Revenu") }}
            </h4>
            <h1 class="grey--text text--darken-4 mb-1">
              &euro;{{ statistics.seller_balance }}
            </h1>
            <p class="grey--text text--darken-2 mb-0">
              {{ user && statistics.account && statistics.account.details_submitted?$t("Plus d'informations"):$t("Completer votre compte") }}
            </p>
          </div>
        </v-card>
<!--      </router-link>-->
    </v-col>
    <v-col
      cols="12"
      sm="6"
      lg="4"
    >
      <router-link
        to="/seller/packages"
        class="text-decoration-none"
      >
        <base-card>
          <div class="px-5 py-9 text-center">
            <h4 class="font-600 grey--text text--darken-2 mb-2">
              {{ $t("Ventes") }}
            </h4>
            <h1 class="grey--text text--darken-4 mb-1">
              {{ statistics.seller_sales_count }}
            </h1>
            <p class="grey--text text--darken-2 mb-0">
              {{ $t("Plus d'informations") }}
            </p>
          </div>
        </base-card>
      </router-link>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      lg="4"
    >
      <router-link
        to="/seller/packages"
        class="text-decoration-none"
      >
        <base-card>
          <div class="px-5 py-9 text-center">
            <h4 class="font-600 grey--text text--darken-2 mb-2">
              {{ $t("Colis à expédier") }}
            </h4>
            <h1 class="grey--text text--darken-4 mb-1">
              {{ statistics.seller_parcels_for_delivery }}
            </h1>
            <p class="grey--text text--darken-2 mb-0">
              {{ $t("Plus d'informations") }}
            </p>
          </div>
        </base-card>
      </router-link>
    </v-col>

    <v-col
      cols="12"
      sm="12"
    >
      <base-card>
        <v-card-text class="text-16 font-600 grey--text text--darken-4">
          {{ $t("Ventes period de 12 mois") }}
        </v-card-text>
        <div class="pa-5">
          <div id="chart">
            <apexchart
              type="area"
              height="250"
              :options="chartOptions"
              :series="series"
            />
          </div>
        </div>
      </base-card>
    </v-col>
  </div>
</template>

<script>


  import { mapState } from 'vuex'
  import ShopDocsAlert from "@/views/vendor/ShopDocsAlert.vue";
  export default {
    components: {
      ShopDocsAlert

    },
    props: {
      statistics: { type: Object, default: () => {} },
    },
    data () {
      return {
        isSidebar: false,
        items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
        options: {

          chart: {
            height: 265,
            type: 'area',
            toolbar: {
              show: false,
            },
          },
        },
        series: [],
        chartOptions: {
          colors: ['#D23F57'],
          chart: {
            height: 265,
            type: 'area',
            toolbar: {
              show: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          grid: {
            show: false,
          },
          xaxis: {
            type: 'datetime',
            categories: [],
            /* categories: ['2018-09-19T00:00:00.000Z', '2018-09-19T01:30:00.000Z', '2018-09-19T02:30:00.000Z', '2018-09-19T03:30:00.000Z', '2018-09-19T04:30:00.000Z', '2018-09-19T05:30:00.000Z', '2018-09-19T06:30:00.000Z', '2018-09-19T07:30:00.000Z', '2018-09-19T08:30:00.000Z'],
            */labels: {
              show: true,
            },
          },
          yaxis: {
            show: false,
          },
          markers: {
            size: 4,
          },
          stroke: {
            width: 1,
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm',
            },
          },

        },

        dashboardCard: [
          {
            titleOne: 'Earnings (before taxes)',
            titleTwo: '$30450.00',
            titleThree: 'after associated vendor fees',
          },
          {
            titleOne: 'Your balance',
            titleTwo: '$4000.00',
            titleThree: 'Will be processed on Feb 15, 2021',
          },
          {
            titleOne: 'Pending Orders',
            titleTwo: '08',
            titleThree: '7/3/2020 - 8/1/2020',
          },
        ],

      }
    },
    computed: {
      ...mapState({
        user: 'user',
        login: 'login',
        displayNotification: 'display_notification',
      }),
    },
    watch: {
      statistics () {
        this.setData()
      },
    },
    created () {
      this.setData()
    },
    methods: {
      setData () {
        this.chartOptions.xaxis.categories = this.statistics.seller_sales.map((e) => e.created_at)
        this.series.push({
          name: this.$t('Ventes'),
          data: this.statistics.seller_sales.map((e) => e.sub_total),
          /* data: [70, 60, 80, 51, 42, 109, 100, 50, 80], */
        })
      },
    },

  }
</script>
