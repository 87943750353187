<template>
     <v-alert
        type="warning"
        dense
        dismissible
      >
        {{ $t("Il manque des informations à votre profil de payment") }}
        <v-btn
          depressed
          color="primary"
          :href="url"
        >
          {{
            $t("Mise à jour du profil de payment")
          }}
        </v-btn>
      </v-alert>
</template>
<script>


  import { mapState } from 'vuex'
  export default {
    components: {

    },
    props: {
      url: { type: String, default: ""},
    },
    data () {
      return {
        isSidebar: false,
        items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
        options: {

          chart: {
            height: 265,
            type: 'area',
            toolbar: {
              show: false,
            },
          },
        },
        series: [],
        chartOptions: {
          colors: ['#D23F57'],
          chart: {
            height: 265,
            type: 'area',
            toolbar: {
              show: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'smooth',
          },
          grid: {
            show: false,
          },
          xaxis: {
            type: 'datetime',
            categories: [],
            /* categories: ['2018-09-19T00:00:00.000Z', '2018-09-19T01:30:00.000Z', '2018-09-19T02:30:00.000Z', '2018-09-19T03:30:00.000Z', '2018-09-19T04:30:00.000Z', '2018-09-19T05:30:00.000Z', '2018-09-19T06:30:00.000Z', '2018-09-19T07:30:00.000Z', '2018-09-19T08:30:00.000Z'],
            */labels: {
              show: true,
            },
          },
          yaxis: {
            show: false,
          },
          markers: {
            size: 4,
          },
          stroke: {
            width: 1,
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm',
            },
          },

        },

        dashboardCard: [
          {
            titleOne: 'Earnings (before taxes)',
            titleTwo: '$30450.00',
            titleThree: 'after associated vendor fees',
          },
          {
            titleOne: 'Your balance',
            titleTwo: '$4000.00',
            titleThree: 'Will be processed on Feb 15, 2021',
          },
          {
            titleOne: 'Pending Orders',
            titleTwo: '08',
            titleThree: '7/3/2020 - 8/1/2020',
          },
        ],

      }
    },
    computed: {
      ...mapState({
        user: 'user',
        login: 'login',
        displayNotification: 'display_notification',
      }),
    },

    created () {
      this.setData()
    },
    methods: {
      setData () {
        this.chartOptions.xaxis.categories = this.statistics.seller_sales.map((e) => e.created_at)
        this.series.push({
          name: this.$t('Ventes'),
          data: this.statistics.seller_sales.map((e) => e.sub_total),
          /* data: [70, 60, 80, 51, 42, 109, 100, 50, 80], */
        })
      },
    },

  }
</script>

